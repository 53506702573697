<template>
  <div class="container">
    <div v-if="preview" class="group-title-lg">
      Anteprima dell'annuncio
    </div>
    <form class="scroll" @submit.prevent="updateCampaign()">
      <div v-if="preview" class="group group-100 box-ad-image">
        <img class="ad-image" :src="campaign.companyData?.logo_url" alt="logo" @click="openInNewTab(campaign.companyData.logo_url)">
        <img class="ad-image" :src="campaign.companyData?.cover_url" alt="cover" @click="openInNewTab(campaign.companyData.cover_url)">
      </div>
      <div v-if="preview" class="group group-50">
        <material-input :disabled="preview" type="text" label="Nome Azienda" autocomplete="off" :value="campaign.co_name" />
      </div>
      <div v-if="preview" class="group group-50">
        <material-input :disabled="preview" type="text" label="Tipologia" autocomplete="off" :value="campaign.co_type" />
      </div>
      <div v-if="preview" class="group group-50">
        <material-input :disabled="preview" type="text" label="Nome Admin" autocomplete="off" :value="campaign.admin_first_name" />
      </div>
      <div v-if="preview" class="group group-50">
        <material-input :disabled="preview" type="text" label="Cognome Admin" autocomplete="off" :value="campaign.admin_last_name" />
      </div>
      <div v-if="preview" class="group group-50">
        <material-input :disabled="preview" type="text" label="Email Aziendale" autocomplete="off" :value="campaign.admin_email" />
      </div>
      <div v-if="preview" class="group group-50">
        <material-input :disabled="preview" type="text" label="Nazione" autocomplete="off" :value="campaign.country" />
      </div>
      <div v-if="preview" class="group group-50">
        <material-input :disabled="preview" type="text" label="Telefono Aziendale" autocomplete="off" :value="campaign.admin_phone" />
      </div>
      <div v-if="preview" class="group group-50">
        <material-input :disabled="preview" type="text" label="Lingua Piattaforma" autocomplete="off" :value="campaign.locale" />
      </div>

      <div class="group group-50">
        <material-input
          id="title"
          type="text"
          label="Titolo"
          autocomplete="off"
          :value="campaign.title"
          required
          @input="(e) => (campaign.title = e)"
        />
      </div>

      <div class="group group-50" style="margin-top: 2.9rem">
        <v-select
          v-model="jobLocations"
          class="inputMaterial"
          :options="searchResults"
          label="description"
          multiple
          :dropdown-should-open="dropdownShouldOpen"
          @search="update"
          @option:selected="addSelectedPlaces"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'jobLocation'"
        />
        <span
          class="bar required"
          :class="{
            activeBar: activeBar === 'jobLocation',
            active: jobLocations.length === 0,
          }"
        />
        <label>
          Località
          <span style="font-size: 20px; position: absolute; top: -3px; right: -10px">*</span>
        </label>
      </div>
      <div v-if="isAutogrill" class="goup group-50">
        <div class="switch-container">
          <span class="switch" @click="campaign.career_page = !campaign.career_page">
            <input v-model="campaign.career_page" type="checkbox" :checked="campaign.career_page">
            <div class="slider round" />
          </span>
          <span style="color: white; margin-top: 5px">Pubblica sulla pagina "Lavora con noi"</span>
        </div>
      </div>
      <div class="group group-50">
        <material-input
          v-if="campaign.companyData"
          id="corporateVideo"
          type="text"
          label="Video Corporate"
          autocomplete="off"
          :value="campaign.companyData.corporate_video_url"
          @input="(e) => (campaign.companyData.corporate_video_url = e)"
          @click="openInNewTab(campaign.companyData.corporate_video_url)"
        />
        <material-input
          v-else
          id="corporateVideo"
          type="text"
          label="Video Corporate"
          autocomplete="off"
          :value="campaign.corporate_video"
          @input="(e) => (campaign.corporate_video = e)"
        />
      </div>
      <div class="group group-50">
        <material-input type="text" label="Sito Web" autocomplete="off" :value="campaign.companyData?.website ?? campaign.website" @input="(e) => (campaign.companyData != undefined ? campaign.companyData.website = e : null)" />
      </div>
      <div class="group group-50">
        <material-input
          type="text"
          label="Privacy Policy"
          autocomplete="off"
          :value="campaign.companyData?.privacy_policy_url"
          @input="(e) => (campaign.companyData != undefined ? campaign.companyData.privacy_policy_url = e : null)"
          @click="openInNewTab(campaign.companyData?.privacy_policy_url)"
        />
      </div>

      <div class="group group-50">
        <material-input type="number" label="Ral Min" autocomplete="off" :value="campaign.ral_min" @input="(e) => (campaign.ral_min = e)" />
      </div>
      <div class="group group-50">
        <material-input type="number" label="Ral Max" autocomplete="off" :value="campaign.ral_max" @input="(e) => (campaign.ral_max = e)" />
      </div>

      <div class="group group-100">
        <QuillEditor v-model:content="description" style="min-height: 150px; color: white" content-type="html" />
      </div>
      <div v-if="campaign.form_id" class="group group-50" style="display: flex; flex-direction: row; justify-items: center">
        <div class="group" style="width: 100%">
          <material-input
            id="minPoints"
            type="number"
            label="Punteggio minimo"
            autocomplete="off"
            :value="campaign.form_score"
            @input="(e) => (campaign.form_score = e)"
          />
        </div>
        <div class="link-btn">
          <a :href="'https://cving.typeform.com/to/' + campaign.form_id" target="_blank" style="color: white">
            <svg style="width: 20px; height: 20px; margin-top: 1px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z" />
            </svg>
          </a>
        </div>
      </div>
      <div class="group group-100 switchInTitle" style="color: white">
        <div>Video-colloquio</div>
        <div class="switch-container">
          <span class="switch" @click="campaign.has_video = !campaign.has_video">
            <input v-model="campaign.has_video" type="checkbox" :checked="campaign.has_video">
            <div class="slider round" />
          </span>
          <span style="color: white; margin-top: 5px" v-text="campaign.has_video ? 'On' : 'Off'" />
        </div>
      </div>
      <div v-if="campaign.has_video" class="group group-100">
        <material-input
          id="domanda1"
          type="text"
          label="Domanda 1"
          autocomplete="off"
          placeholder="Domanda"
          :value="campaign.pi[0]"
          :required="campaign.has_video"
          @input="(e) => (campaign.pi[0] = e)"
        />
      </div>
      <div v-if="campaign.has_video" class="group group-100">
        <material-input
          id="domanda2"
          type="text"
          label="Domanda 2"
          autocomplete="off"
          placeholder="Domanda"
          :value="campaign.pi[1]"
          :required="campaign.has_video"
          @input="(e) => (campaign.pi[1] = e)"
        />
      </div>
      <div v-if="campaign.has_video" class="group group-100">
        <material-input
          id="domanda3"
          type="text"
          label="Domanda 3"
          placeholder="Domanda"
          autocomplete="off"
          :value="campaign.pi[2]"
          :required="campaign.has_video"
          @input="(e) => (campaign.pi[2] = e)"
        />
      </div>
      <div v-if="campaign.has_video" class="group group-100">
        <material-input
          id="domanda4"
          type="text"
          label="Domanda 4"
          autocomplete="off"
          placeholder="Domanda"
          :value="campaign.pi[3]"
          :required="campaign.has_video"
          @input="(e) => (campaign.pi[3] = e)"
        />
      </div>
      <div v-if="campaign.has_video" class="group group-100">
        <material-input
          id="domanda5"
          type="text"
          label="Domanda 5"
          autocomplete="off"
          placeholder="Domanda"
          :value="campaign.pi[4]"
          :required="campaign.has_video"
          @input="(e) => (campaign.pi[4] = e)"
        />
      </div>

      <div v-if="isUmana" class="group group-100" style="color: white">
        Umana
      </div>
      <div v-if="isUmana" class="group group-50">
        <v-select
          v-model="campaign.umanacategory1"
          class="inputMaterial"
          filterable
          :options="umanaValues1"
          :clearable="!preview"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'umana1'"
        />
        <span class="bar" :class="{ activeBar: activeBar === 'umana1' }" />
        <label>Umana</label>
      </div>
      <div v-if="isUmana" class="group group-50">
        <v-select
          v-model="campaign.umanacategory2"
          class="inputMaterial"
          filterable
          :options="umanaValues2"
          :clearable="!preview"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'umana2'"
        />
        <span class="bar" :class="{ activeBar: activeBar === 'umana2' }" />
        <label>Umana 2</label>
      </div>
      <div v-if="isUmana" class="group group-50">
        <v-select
          v-model="campaign.umanacategory3"
          class="inputMaterial"
          filterable
          :options="umanaValues3"
          :clearable="!preview"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'umana3'"
        />
        <span class="bar" :class="{ activeBar: activeBar === 'umana3' }" />
        <label>Umana3</label>
      </div>
      <div v-if="isUmana" class="group group-50">
        <v-select
          v-model="campaign.umanaseniority"
          class="inputMaterial"
          filterable
          :options="umanaSeniority"
          :clearable="!preview"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'umanaseniority'"
        />
        <span class="bar" :class="{ activeBar: activeBar === 'umanaseniority' }" />
        <label>Umana seniority</label>
      </div>

      <!-- Easy post -->
      <div class="group group-100 group-title">
        Informazioni candidato
      </div>
      <div class="group group-50">
        <v-select
          v-model="campaign.id_skills"
          class="inputMaterial"
          :options="
            getCompetences.filter((o) => campaign.id_skills?.indexOf(o) < 0)
          "
          multiple
          taggable
          push-tags
          searchable
          label="description"
          :reduce="(obj) => obj.id"
          :clearable="!preview"
          @search="getSkills"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'competence'"
        >
          <template #no-options="{}">
            Inserisci una competenza e premi invio
          </template>
        </v-select>
        <span class="bar" :class="{ activeBar: activeBar === 'competence' }" />
        <label>Competenze</label>
      </div>
      <div class="group group-50">
        <v-select
          v-model="campaign.certifications"
          class="inputMaterial"
          :options="
            getCertifications.filter(
              (o) => campaign.certifications?.indexOf(o) < 0
            )
          "
          multiple
          taggable
          push-tags
          searchable
          :clearable="!preview"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'certifications'"
        >
          <template #no-options="{}">
            Inserisci una certificazione e premi invio
          </template>
        </v-select>
        <span class="bar" :class="{ activeBar: activeBar === 'certifications' }" />
        <label>Certificazioni</label>
      </div>

      <div class="group group-50">
        <v-select
          v-model="campaign.id_correlation_seniority"
          class="inputMaterial"
          label="description"
          :reduce="(c) => c.correlationId"
          item-text="description"
          item-value="correlationId"
          filterable
          :options="senioritiesList"
          :clearable="!preview"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'seniority'"
        />
        <span class="bar" :class="{ activeBar: activeBar === 'seniority' }" />
        <label>Seniority</label>
      </div>
      <div class="group group-50">
        <v-select
          v-model="campaign.id_correlation_education_level"
          class="inputMaterial"
          label="description"
          filterable
          :options="educationalLevels"
          :reduce="(c) => c.correlationId"
          item-text="description"
          item-value="correlationId"
          :clearable="!preview"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'education'"
          @option:selected="campaign.id_correlation_education_level = $event"
        />
        <span class="bar" :class="{ activeBar: activeBar === 'education' }" />
        <label>Livello di istruzione</label>
      </div>
      <div v-if="!preview" class="languages-container">
        <div class="group group-50">
          <v-select
            v-model="langRequired"
            class="inputMaterial"
            multiple
            label="description"
            :reduce="(c) => c.correlationId"
            item-text="description"
            item-value="correlationId"
            filterable
            :options="languagesList"
            :clearable="!preview"
            @search:blur="activeBar = false"
            @search:focus="activeBar = 'langRequired'"
          />
          <span class="bar" :class="{ activeBar: activeBar === 'langRequired' }" />
          <label>Lingue richieste</label>
        </div>
        <div class="group group-50 group-50-languages">
          <v-select
            v-model="langLevel"
            class="inputMaterial"
            multiple
            label="description"
            :reduce="(c) => c.correlationId"
            item-text="description"
            item-value="correlationId"
            filterable
            :options="languageLevels"
            :clearable="!preview"
            @search:blur="activeBar = false"
            @search:focus="activeBar = 'langRequired'"
          />
          <span class="bar" :class="{ activeBar: activeBar === 'langRequired' }" />
          <label>Livello della lingua</label>
        </div>
        <button v-if="!preview" @click.stop.prevent="addNewLanguage(langRequired[0], langLevel[0])">
          +
        </button>
      </div>
      <div v-if="campaign.langRequired?.length > 0" class="langs-selected-wrapper">
        <div v-for="(lang, index) in campaign.langRequired" :key="'lang__' + index" @click.stop.prevent="!preview && campaign.langRequired.splice(index, 1)">
          <span>{{ lang.lang.description }}: {{ lang.level.description }}</span>
          <svg v-if="!preview" style="width: 18px; height: 18px" viewBox="0 0 24 24">
            <path fill="white" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
          </svg>
        </div>
      </div>

      <div class="group group-50">
        <v-select
          v-model="campaign.protected_category"
          class="inputMaterial"
          multiple
          label="description"
          filterable
          :clearable="!preview"
          :options="yesOrNot"
          :reduce="(c) => c.value"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'protectCategory'"
        />
        <span class="bar" :class="{ activeBar: activeBar === 'protectCategory' }" />
        <label>Appartenente alle categorie protette</label>
      </div>

      <div class="group group-100 group-title">
        Offerta
      </div>

      <div class="group group-50">
        <v-select
          v-model="campaign.contract_type"
          class="inputMaterial"
          label="description"
          :reduce="(c) => c.correlationId"
          item-text="description"
          item-value="correlationId"
          filterable
          :options="typeOfContract"
          :clearable="false"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'contractTypology'"
        />
        <span class="bar" :class="{ activeBar: activeBar === 'contractTypology' }" />
        <label>Tipologia Contratto</label>
      </div>
      <div class="group group-50">
        <v-select
          v-model="campaign.id_correlation_work_hour"
          class="inputMaterial"
          label="description"
          :reduce="(c) => c.correlationId"
          item-text="description"
          item-value="correlationId"
          filterable
          :options="workHours"
          :clearable="!preview"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'protectCategory'"
        />
        <span class="bar" :class="{ activeBar: activeBar === 'protectCategory' }" />
        <label>Orario di lavoro</label>
      </div>
      <div class="group group-50">
        <v-select
          v-model="campaign.id_correlation_remote_working"
          class="inputMaterial"
          label="description"
          :reduce="(c) => c.correlationId"
          item-text="description"
          item-value="correlationId"
          filterable
          :options="remoteWorkingOpt"
          :clearable="!preview"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'remoteWorking'"
        />
        <span class="bar" :class="{ activeBar: activeBar === 'remoteWorking' }" />
        <label>Remote Working</label>
      </div>
      <div class="group group-50">
        <v-select
          v-model="campaign.id_correlation_benefits"
          class="inputMaterial"
          label="description"
          :reduce="(c) => c.correlationId"
          item-text="description"
          item-value="correlationId"
          multiple
          filterable
          :options="benefits"
          :clearable="!preview"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'benefits'"
        />
        <span class="bar" :class="{ activeBar: activeBar === 'benefits' }" />
        <label>Benefits</label>
      </div>
      <div class="group group-50">
        <v-select
          v-model="campaign.availability_to_travel"
          class="inputMaterial"
          multiple
          label="description"
          filterable
          :options="yesOrNot"
          :reduce="(c) => c.value"
          :clearable="!preview"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'availabilityToTravel'"
        />
        <span class="bar" :class="{ activeBar: activeBar === 'availabilityToTravel' }" />
        <label>Disponibilità a viaggi/turni</label>
      </div>
      <div class="group group-50" />
      <!-- fine easy -->

      <div class="group group-50" style="margin-top: 3rem">
        <v-select
          v-model="extension"
          class="inputMaterial"
          :reduce="(c) => c.value"
          label="text"
          filterable
          :options="optionsDurata"
          :clearable="false"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'extension'"
        />
        <span class="bar" :class="{ activeBar: activeBar === 'extension' }" />
        <label>Estendi la campagna</label>
      </div>
      <div v-if="extension === 1 " class="group group-50 group-datepicker" style="margin-top: 3rem">
        <date-picker
          :key="customDate"
          v-model:value="customDate"
          format="DD/MM/YYYY"
          :shortcuts="shortcuts"
          :disabled-date="disabledDate"
          popup-class="campaignNewCustomDate"
          :append-to-body="false"
          @change="updateTimePicker"	
        />

        <!-- Selettore di orari -->
        <date-picker
          v-model:value="customTime"
          type="time"
          format="HH:mm"
          placeholder="Seleziona l'orario"
          :editable="true"
          :clearable="true"
          :disabled-time="disabledTime"
          popup-class="campaignNewCustomTime"
          :append-to-body="false"
        >
          <template #icon-calendar="">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <title>clock-time-five-outline</title>
              <path
                d="M12 20C16.4 20 20 16.4 20 12S16.4 4 12 4 4 7.6 4 12 7.6 20 12 20M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M15.3 16.2L14 17L11 11.8V7H12.5V11.4L15.3 16.2Z"
              />
            </svg>
          </template>
        </date-picker>
      </div>
      <div class="group group-100">
        <span>Visibilità</span>
      </div>
      <div class="group group-100" style="display: flex; flex-direction: column">
        <div class="switch-container" style="margin-bottom: 25px">
          <span class="switch" @click="permissions.canUse('COMPANY_CAMPAIGN_SETTING_VIEW') ? (campaign.private = !campaign.private) : null">
            <input v-model="isCampaignPrivate" type="checkbox" :checked="!campaign.private">
            <div class="slider round" :class="{ 'disabled' : !permissions.canUse('COMPANY_CAMPAIGN_SETTING_VIEW') }" />
          </span>
          <span style="color: white; margin-top: 5px">Cving</span>
        </div>
        <div class="switch-container" style="margin-bottom: 25px">
          <span class="switch" @click="permissions.canUse('COMPANY_CAMPAIGN_SETTING_VIEW') ? (campaign.google = !campaign.google) : null">
            <input v-model="campaign.google" type="checkbox" :checked="campaign.google">
            <div class="slider round" :class="{ 'disabled' : !permissions.canUse('COMPANY_CAMPAIGN_SETTING_VIEW') }" />
          </span>
          <span style="color: white; margin-top: 5px">Google</span>
        </div>
        <div class="switch-container" style="margin-bottom: 25px">
          <span class="switch" @click="permissions.canUse('COMPANY_CAMPAIGN_SETTING_VIEW') ? (campaign.visual_feedback = !campaign.visual_feedback) : null">
            <input v-model="campaign.visual_feedback" type="checkbox" :checked="campaign.visual_feedback">
            <div class="slider round" :class="{ 'disabled' : !permissions.canUse('COMPANY_CAMPAIGN_SETTING_VIEW') }" />
          </span>
          <span style="color: white; margin-top: 5px">Visual Feedback</span>
        </div>
        <div v-if="campaign.indeed_integration" class="switch-container" style="margin-bottom: 25px">
          <span class="switch" @click="permissions.canUse('COMPANY_CAMPAIGN_SETTING_VIEW') ? (campaign.indeed = !campaign.indeed) : null">
            <input v-model="campaign.indeed" type="checkbox" :checked="campaign.indeed">
            <div class="slider round" :class="{ 'disabled' : !permissions.canUse('COMPANY_CAMPAIGN_SETTING_VIEW') }" />
          </span>
          <span style="color: white; margin-top: 5px">Indeed</span>
        </div>
        <div class="switch-container">
          <span class="switch" @click="permissions.canUse('COMPANY_CAMPAIGN_SETTING_VIEW') ? (campaign.bakeca = !campaign.bakeca) : null">
            <input v-model="campaign.bakeca" type="checkbox" :checked="campaign.bakeca">
            <div class="slider round" :class="{ 'disabled' : !permissions.canUse('COMPANY_CAMPAIGN_SETTING_VIEW') }" />
          </span>
          <span style="color: white; margin-top: 5px">Bakeca</span>
        </div>
      </div>

      <!-- #region indeed -->
      <div v-if="campaign.indeed" class="group group-50" style="margin-top: 30px">
        <material-input
          id="title_indeed"
          type="text"
          label="Titolo Indeed"
          autocomplete="off"
          :value="campaign.title_indeed"
          :required="campaign.indeed"
          @input="(e) => (campaign.title_indeed = e)"
        />
      </div>
      <div v-if="campaign.indeed" class="group group-50" style="margin-top: 3rem">
        <v-select
          v-model="indeedLocations"
          class="inputMaterial"
          :options="searchResultsIndeed"
          label="description"
          multiple
          :dropdown-should-open="dropdownShouldOpen"
          @search="updateIndeedLocations"
          @option:selected="addSelectedIndeedPlaces"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'indeedLocation'"
        />
        <span
          class="bar required"
          :class="{
            activeBar: activeBar === 'indeedLocation',
            active: campaign.indeed && indeedLocations.length === 0,
          }"
        />
        <label>
          Località Indeed
          <span style="font-size: 20px; position: absolute; top: -3px; right: -10px">*</span>
        </label>
      </div>
      <!-- #endregion -->
      
      <span v-if="preview && campaign.easy_post_status == 2" style="margin-top: 3em;" class="group group-100">
        Inserisci la denominazione dell'alias da associare a "piattaforma+. Riceverai un'email a piattaforma@cving.com per procedere con l'accesso."
      </span>
      <div v-if="preview && campaign.easy_post_status == 2" class="group group-100" style="display: flex; flex-direction: row; align-items: flex-end; margin-top: 0; gap: 25px">
        <material-input
          v-if="!campaign.piattaforma"
          type="email"
          label="piattaforma+alias@cving.com"
          autocomplete="off"
          :value="campaign.alias"
          style="width: 48%;"
          :placeholder-bicolor="true"
          @input="(e) => (campaign.alias = e)"
        />

        <material-input
          v-else
          type="email"
          label=""
          autocomplete="off"
          :value="campaign.piattaforma"
          style="width: 48%;"
          :disabled="true"
        />

        <material-button v-if="!campaign.piattaforma" text="Crea utente" type="info" style="width: 130px;" @click.stop.prevent="createUserPiattaforma()" />
      </div>   
      <!-- <div v-if="preview" class="group group-50" style="margin-top: 10px;width: 130px;">     
        
      </div> -->
      
      <div v-if="!preview" class="action-container">
        <material-button text="Visualizza su landing-page" type="info" style="margin-right: 25px" @click.stop.prevent="openCampaignInLandingPage()" />
        <material-button text="Visualizza sul radar" type="info" @click.stop.prevent="openCampaignInRadar()" />
        <material-button text="Aggiorna campagna" type="success" style="float: right" />
      </div>
    </form>
  </div>
</template>

<script>
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import { spinnerStore } from '@/stores/spinner'
import { sbapibackoffice, apicross, api } from '@/api'
import { reactive, computed, toRefs, onBeforeMount, inject } from 'vue'
import moment from 'moment'
import debounce from 'debounce'
import { Loader } from '@googlemaps/js-api-loader'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import axios from 'axios'
import { permissionsStore } from '@/stores/permissions'

export default {
  components: {
    QuillEditor,
    DatePicker
  },
  props: {
    preview: {
      type: Boolean,
      default: false
    },
    autogrill: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    umana: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:selected', 'closePanel'],
  setup(props, { emit }) {
    const spinner = spinnerStore()
    const toast = inject('$toast')
    const state = reactive({
      activeBar: false,
      service: null,
      servicePlaces: null,
      searchResults: [],
      searchResultsIndeed: [],
      jobLocations: [],
      indeedLocations: [],
      modalOptions: '',
      country: '',
      city: '',
      cityIsoCode: '',
      country_iso_code: '',
      state: '',
      county: '',
      latitude: null,
      longitude: null,
      optionsDurata: [
        { value: 0, text: 'Non estendere la campagna' },
        { value: 1, text: 'Estendi la campagna' }
      ],
      customDate: null,
      customTime: null,
      openTimePicker: false,
      extension: 0,
      langRequired: '',
      langLevel: '',
      educationalLevels: [],
      typeOfContract: [],
      workHours: [],
      remoteWorkingOpt: [],
      senioritiesList: [],
      languageLevels: [],
      languagesList: [],
      benefits: [],
      getCompetences: []
    })
    const permissions = permissionsStore()
    const getSkills = (search, loading) => {
      if (search.length) {
        loading(true)
        debounce((str) => {
          let lang = 'it'
          apicross
            .get('/jobskills?language=' + lang + '&skills=' + str)
            .then((res) => {
              state.getCompetences = res.data.filter((skill) => skill.active)
            })
            .catch((err) => {
              console.log(err)
            })
            .finally(() => {
              loading(false)
            })
        }, 300)(search)
      }
    }
    const shortcuts = computed(() => {
      return [
        {
          text: '1 mese',
          onClick() {
            const date = new Date(campaign.value.expire)
            date.setMonth(date.getMonth() + 1)
            return date
          }
        },
        {
          text: '3 mesi',
          onClick() {
            const date = new Date(campaign.value.expire)
            date.setMonth(date.getMonth() + 3)
            return date
          }
        },
        {
          text: '6 mesi',
          onClick() {
            const date = new Date(campaign.value.expire)
            date.setMonth(date.getMonth() + 6)
            return date
          }
        },
        {
          text: '9 mesi',
          onClick() {
            const date = new Date(campaign.value.expire)
            date.setMonth(date.getMonth() + 9)
            return date
          }
        },
        {
          text: '12 mesi',
          onClick() {
            const date = new Date(campaign.value.expire)
            date.setMonth(date.getMonth() + 12)
            return date
          }
        }
      ]
    })
    const getCertifications = computed(() => {
      return []
    })
    const isCampaignPrivate = computed({
      get: () => {
        return !campaign.value.private
      },
      set: (value) => (campaign.value.private = value)
    })
    const campaign = computed({
      get: () => {
        return props.selected
      },
      set: (value) => emit('update:selected', value)
    })
    const isUmana = computed({
      get: () => {
        return props.umana
      }
    })
    const isAutogrill = computed({
      get: () => {
        return props.autogrill
      }
    })

    const description = computed({
      get: () => {
        return campaign.value.description ? campaign.value.description : ''
      },
      set: (value) => (campaign.value.description = value)
    })
    const yesOrNot = computed({
      get: () => {
        return [
          { description: 'Si', value: true },
          { description: 'No', value: false }
        ]
      }
    })
    onBeforeMount(async () => {
      const loader = new Loader({
        apiKey: 'AIzaSyCDr8m_4XWcfZjgnLiodPX2fLDkX274bdU',
        version: 'weekly',
        libraries: ['places'],
        language: 'it'
      })

      loader.load().then((google) => {
        state.service = new google.maps.places.AutocompleteService()
        let map = new google.maps.Map(document.createElement('div'))
        state.servicePlaces = new google.maps.places.PlacesService(map)
        if (props.selected) {
          // locations
          let tempLocations = []
          for (let a = 0; a < props.selected.locations.length; a++) {
            let p = props.selected.locations[a].split('_')
            tempLocations.push({
              forBackend: props.selected.locations[a],
              formatted: p[0],
              description: p[0] + ' ' + p[1] + ' ' + p[2],
              str: p[0] + ' ' + p[1],
              index: a,
              geometry: {
                location: {
                  lat: p[4],
                  lng: p[5]
                }
              }
            })
          }
          Object.assign(state.jobLocations, tempLocations)

          // indeed locations
          let tempIndeedLocations = []
          for (let a = 0; a < props.selected.indeed_locations.length; a++) {
            let p = props.selected.indeed_locations[a].split('_')
            tempIndeedLocations.push({
              forBackend: props.selected.indeed_locations[a],
              formatted: p[0],
              description: p[0] + ' ' + p[1] + ' ' + p[2],
              str: p[0] + ' ' + p[1],
              index: a,
              geometry: {
                location: {
                  lat: p[4],
                  lng: p[5]
                }
              }
            })
          }
          Object.assign(state.indeedLocations, tempIndeedLocations)
        }
      })

      await axios
        .all([
          apicross.get('/jobscontracts?language=it'),
          apicross.get('/jobsbenefits?language=it'),
          apicross.get('/jobsremoteworking?language=it'),
          apicross.get('/jobsworkinghours?language=it'),
          apicross.get('/jobslanguages?language=it'),
          apicross.get('/jobslanguagelevels?language=it'),
          apicross.get('/jobseducationlevels?language=it'),
          apicross.get('/jobsseniorities?language=it')
        ])
        .then(
          axios.spread(
            (
              contract,
              benefits,
              remoteworking,
              workhours,
              languages,
              languagelevels,
              educationlevels,
              jobsseniorities
            ) => {
              state.benefits = benefits.data

              state.educationalLevels = educationlevels.data.filter(
                (l) => l.active
              )

              state.workHours = workhours.data.filter((l) => l.active)
              state.remoteWorkingOpt = remoteworking.data.filter(
                (l) => l.active
              )
              state.typeOfContract = contract.data.filter((l) => l.active)
              state.senioritiesList = jobsseniorities.data.filter(
                (l) => l.active
              )
              state.languageLevels = languagelevels.data.filter(
                (l) => l.active
              )
              state.languagesList = languages.data.filter((l) => l.active)

              campaign.value.langRequired = recreateLangRequired(
                campaign.value.lang_required
              )

              if (campaign.value.id_correlation_education_level === 0) {
                campaign.value.id_correlation_education_level = { 'correlationId': 0, 'description': 'Non specificato', 'active': true }
              }
              if (campaign.value.id_correlation_seniority === 0) {
                campaign.value.id_correlation_seniority = { 'correlationId': 0, 'description': 'Non specificato', 'active': true }
              }
              if (campaign.value.contract_type === 0) {
                campaign.value.contract_type = { 'correlationId': 0, 'description': 'Non specificato', 'active': true }
              }
              if (campaign.value.id_correlation_remote_working === 0) {
                campaign.value.id_correlation_remote_working = { 'correlationId': 0, 'description': 'Non specificato', 'active': true }
              }
            }
          )
        )
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          console.log('finish await')
          spinner.hide()
        })

      let campaignExpireDate = moment.utc(campaign.value.expire)
      campaignExpireDate = new Date(campaignExpireDate)
      //const expireDateWithAddedMinutes = new Date(campaignExpireDate.getTime() + 5 * 60 * 1000)
      state.customDate = campaignExpireDate
      state.customTime = campaignExpireDate
    })

    const recreateLangRequired = (obj) => {
      let formatted = []
      obj.forEach((d) => {
        let lang = state.languagesList.find((l) => {
          return Number(l.correlationId) == Number(d.languageId)
        })
        let level = state.languageLevels.find((l) => {
          return Number(l.correlationId) == Number(d.languageLevelId)
        })
        formatted.push({
          lang: lang,
          level: level
        })
      })
      return formatted
    }

    const addNewLanguage = (langId, levelId) => {
      const lang = state.languagesList.find((f) => f.correlationId === langId)
      const level = state.languageLevels.find(
        (f) => f.correlationId === levelId
      )

      campaign.value.langRequired.push({ lang, level })
      state.langRequired = ''
      state.langLevel = ''
    }

    const update = (loc) => {
      if (loc) {
        state.service.getPlacePredictions(
          {
            input: loc,
            types: ['(cities)']
          },
          displaySuggestions
        )
      }
    }

    const updateIndeedLocations = (input) => {
      if (!input) return

      state.service.getPlacePredictions(
        {
          input,
          types: ['(regions)']
        },
        (predictions, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            state.searchResultsIndeed = []
            return
          }
          state.searchResultsIndeed = predictions
        }
      )
    }

    const displaySuggestions = (predictions, status) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        state.searchResults = []
        return
      }
      state.searchResults = predictions
    }
    const addSelectedPlaces = (e) => {
      state.servicePlaces.getDetails(
        {
          placeId: e[state.jobLocations.length - 1].place_id
        },
        createGoogleLocations
      )
    }
    const addSelectedIndeedPlaces = (e) => {
      state.servicePlaces.getDetails(
        {
          placeId: e[state.indeedLocations.length - 1].place_id
        },
        createGoogleLocationsIndeed
      )
    }
    const createGoogleLocationsIndeed = (place, serviceStatus) => {
      if (serviceStatus === 'OK') {
        state.indeedLocations.map((item) => {
          if (item.place_id === place.place_id) {
            item['address_components'] = place.address_components
            item['geometry'] = place.geometry
            item['name'] = place.name
          }
          return item
        })
      }
    }

    const createGoogleLocations = (place, serviceStatus) => {
      if (serviceStatus === 'OK') {
        state.jobLocations.map((item) => {
          if (item.place_id === place.place_id) {
            item['address_components'] = place.address_components
            item['geometry'] = place.geometry
            item['name'] = place.name
            item['lat'] = place.geometry.location.lat()
            item['lng'] = place.geometry.location.lng()
          }
          return item
        })
      }
    }
    const dropdownShouldOpen = (VueSelect) => {
      if (state.searchResults && state.searchResults.length > 0) {
        return VueSelect.open
      }

      return VueSelect.search.length !== 0 && VueSelect.open
    }

    const umanaValues1 = computed(() => {
      return [
        'Legno e arredo',
        'Edilizia e costruzioni',
        'Materiali da costruzione, ceramica e vetro',
        'Industria meccanica, dei macchinari e della lavorazione metalli',
        'Industria elettrica ed elettrotecnica (elettrodomestici, strumentazioni, componentistica)',
        'Industria della gomma e della plastica',
        'Industrie chimiche, farmaceutiche e cosmetiche',
        'Industria della Carta, stampa ed editoria',
        'Moda e lusso (incluso retail)',
        'Commercio e grande distribuzione organizzata (escluso moda e lusso)',
        'Distribuzione energia, gas e servizi idrici',
        'ICT',
        'Telecomunicazioni',
        'Consulenza d\'Impresa (analisi, contabilità, comunicazione, legale, studi professionali)',
        'Servizi alle imprese (pulizia, sicurezza, giardinaggio, manutenzione)',
        'Gestione Risorse Umane (selezione, formazione, organizzazione, gestione)',
        'Banche e assicurazioni',
        'Logistica e Trasporti',
        'Turismo e cultura, ristorazione e hotellerie',
        'Agricoltura',
        'Industria Agroalimentare',
        'Sanità',
        'Servizi alla persona',
        'Pubblica amministrazione'
      ].sort()
    })
    const umanaValues2 = computed(() => {
      return [
        'Ruoli di coordinamento e manageriali',
        'Ruoli specialistico gestionali (coordinano persone)',
        'Ruoli tecnico specifici (non coordina persone)',
        'Ruoli operativi generici',
        'Laureandi/neolaureati disoccupati per formazione preassuntiva'
      ].sort()
    })
    const umanaValues3 = computed(() => {
      return [
        'Gestione e programmazione della produzione (Project management, Supply Chain)',
        'Produzione del bene, erogazione del servizio, punto vendita',
        'Acquisti',
        'Amministrazione, finanza e controllo',
        'Marketing, comunicazione, relazioni esterne',
        'Ricerca e sviluppo',
        'Legale e privacy',
        'Vendite e supporto alla rete commerciale',
        'Servizio clienti e post vendita',
        'HR',
        'Reti e sistemi informativi',
        'Sistema qualità e sicurezza sul lavoro',
        'Segreteria',
        'Ufficio tecnico',
        'Logistica e magazzino',
        'Manutenzione',
        'Recupero crediti'
      ].sort()
    })
    const umanaSeniority = computed(() => {
      return [
        'Senior (+7 anni)',
        'Middle (4-7 anni)',
        'Junior (1-3 anni)',
        'Nessuna esperienza'
      ]
    })

    const saveIndeedLocations = () => {
      campaign.value.indeed_locations = []

      state.indeedLocations.forEach((c) => {
        let tmpCity = ''
        let tmpRegion = ''
        let tmpNation = ''

        if (typeof c === 'object' && c.address_components) {
          for (let i = 0; i < c.address_components.length; i++) {
            let addressType = c.address_components[i].types[0]
            if (addressType === 'country') {
              // Nazione
              tmpNation = c.address_components[i].long_name
            } else if (addressType === 'locality') {
              // Citta'
              tmpCity = c.address_components[i].long_name
            } else if (addressType === 'administrative_area_level_2') {
              // Provincia
              //state.county = c.address_components[i].short_name
            } else if (addressType === 'administrative_area_level_1') {
              // Regione
              tmpRegion = c.address_components[i].long_name
            }
          }

          campaign.value.indeed_locations.push(
            `${tmpCity}_${tmpRegion}_${tmpNation}`
          )
        } else campaign.value.indeed_locations.push(c.forBackend)
        
      })
    }

    const saveLocations = () => {
      campaign.value.city = ''
      campaign.value.locations = []
      state.jobLocations.forEach(function (c, index) {
        if (typeof c === 'object' && c.address_components) {
          for (let i = 0; i < c.address_components.length; i++) {
            let addressType = c.address_components[i].types[0]
            state.city = c.name
            if (addressType === 'country') {
              state.country = c.address_components[i].long_name
              state.country_iso_code = c.address_components[i].short_name
            } else if (
              addressType === 'administrative_area_level_3' ||
              addressType === 'locality'
            ) {
              state.city = c.address_components[i].long_name
              state.cityIsoCode = c.address_components[i].short_name
            } else if (addressType === 'administrative_area_level_2') {
              state.county = c.address_components[i].short_name
            } else if (addressType === 'administrative_area_level_1') {
              state.state = c.address_components[i].long_name
            }
          }
          campaign.value.locations.push(
            state.city +
            '_' +
            state.state +
            '_' +
            state.country +
            '_' +
            state.county +
            '_' +
            state.latitude +
            '_' +
            state.longitude
          )
        } else if (typeof c === 'object' && c.forBackend) {
          campaign.value.locations.push(c.forBackend)
          state.city = c.formatted
        } else {
          return false
        }

        if (c === '' || c === null || c === ' ') {
          if (typeof c !== 'undefined') {
            state.latitude = parseFloat(c.geometry.location.lat())
            state.longitude = parseFloat(c.geometry.location.lng())
          }
        } else {
          try {
            state.latitude = parseFloat(c.geometry.location.lat())
            state.longitude = parseFloat(c.geometry.location.lng())
          } catch (err) {
            //THIS 2 LINES ARE DIFFERENT FROM THE ABOVE!! ABOVE ARE ELEMENTS, UNDER ARE FUNCTIONS!!
            state.latitude = parseFloat(c.geometry.location.lat)
            state.longitude = parseFloat(c.geometry.location.lng)
          }
        }

        if (props.isUmana && state.city == 'Mestre') {
          state.latitude = 45.454816468287326
          state.longitude = 12.213251886842713
        }
        
        campaign.value.city += state.city + '|'

        if (index == 0) {
          campaign.value.latitude = state.latitude
          campaign.value.longitude = state.longitude
        }
      })

      if (campaign.value.city.endsWith('|')) {
        campaign.value.city = campaign.value.city.slice(0, -1)
      }
    }

    const updateCampaign = async () => {
      
      if (state.jobLocations.length === 0) return false
      if (campaign.value.indeed && state.indeedLocations.length === 0)
        return false

      spinner.show()
      saveLocations()
      saveIndeedLocations()
      if (state.extension != 0) {
        let myMomentObject = moment(state.customTime)
        let isoString = myMomentObject.toISOString()
        campaign.value.expire = isoString
        console.log( campaign.value.expire)
        
      }

      let obj = {}
      Object.assign(obj, campaign.value)

      obj.campaign_video_url = obj.corporate_video
      obj.atg_career_page = obj.career_page

      if (!obj.form_id || obj.form_id === '') {
        delete obj.form_id
        delete obj.form_score
      } else {
        delete obj.form_id
      }
      delete obj.lat
      delete obj.lon
      delete obj.corporate_video
      delete obj.career_page

      obj.lang_required =
        JSON.stringify(
          obj.langRequired.map(l => ({
            languageId: l.lang.correlationId,
            languageLevelId: l.level.correlationId
          }))
        )

      obj.id_skills = obj.skills ? obj.skills.map(s => s.id) : []

      delete obj.langRequired

      if (Array.isArray(obj.protected_category)) {
        obj.protected_category = obj.protected_category[0]
      }

      obj.id_correlation_education_level = campaign.value.id_correlation_education_level?.correlationId
      obj.id_correlation_seniority = campaign.value.id_correlation_seniority?.correlationId
      obj.contract_type = campaign.value.contract_type?.correlationId
      obj.id_correlation_remote_working = campaign.value.id_correlation_remote_working?.correlationId
      return await sbapibackoffice
        .put('/campaigns', obj)
        .then(() => {
          emit('closePanel', obj)
          //$toast.success('Euro inviati alle aziende')
          //campaign.value.pi = JSON.parse(obj.pi)
          return null
        })
        .catch((err) => {
          emit('closePanel', { err })
          return { err: err.response.data }
        })
    }

    const openCampaignInLandingPage = () => {
      switch (process.env.VUE_APP_BUILD_ENV) {
        case 'development':
          window.open(
            `https://api.gcp.cving.com/v1/job-offers/id/${campaign.value.cid}/`,
            '_blank'
          )
          break
        case 'staging':
          window.open(
            `https://api.gcpstaging.cving.com/v1/job-offers/id/${campaign.value.cid}/`,
            '_blank'
          )
          break
        case 'production':
          window.open(
            `https://api.cving.com/v1/job-offers/id/${campaign.value.cid}/`,
            '_blank'
          )
          break
      }
    }

    const openCampaignInRadar = () => {
      const { city_slug, slug, cid } = campaign.value

      switch (process.env.VUE_APP_BUILD_ENV) {
        case 'development':
          window.open(
            `https://gcp.cving.com/offerte-lavoro/${city_slug}/${slug}?id=${cid}&verified=true`,
            '_blank'
          )
          break
        case 'staging':
          window.open(
            `https://gcpstaging.cving.com/offerte-lavoro/${city_slug}/${slug}?id=${cid}&verified=true`,
            '_blank'
          )
          break
        case 'production':
          window.open(
            `https://cving.com/offerte-lavoro/${city_slug}/${slug}?id=${cid}&verified=true`,
            '_blank'
          )
          break
      }
    }

    const openInNewTab = (link) => {
      window.open(link, '_blank')
    }

    const createUserPiattaforma = () => {
      console.log(campaign.value)
      spinner.show()
      api.post('/auth/add-new-member-easy-post', { 
        'alias': campaign.value.alias,
        'coid': campaign.value.coid
      }).then(() => {
        toast.success('Utente creato con successo')
        campaign.value.piattaforma = 'piattaforma+' + campaign.value.alias + '@cving.com'
      }).catch(() => {
        toast.error('Errore nella creazione dell\'utente')
      }).finally(spinner.hide)
    }
    const updateTimePicker = () => {
      const date = new Date(state.customDate)
      const year = date.getFullYear()
      const month = date.getMonth() // Mese basato su zero (0 per gennaio, 11 per dicembre)
      const day = date.getDate()

      // Estrai ore e minuti dall'orario
      const time = new Date(state.customTime)
      const hours = time.getHours()
      const minutes = time.getMinutes()

      // Crea una nuova data con anno, mese e giorno da customDate e ore e minuti da customTime
      let updatedTime = new Date(year, month, day, hours, minutes)
      if (updatedTime < new Date(campaign.value.expire)) {
        updatedTime = new Date(campaign.value.expire)
        updatedTime.setMinutes(updatedTime.getMinutes() + 5)
        state.customDate = updatedTime
      
      } 
      state.customTime = updatedTime
      
    }
    // Funzione per disabilitare le date
    const disabledDate = (date) => {
      const expireDate = new Date(campaign.value.expire)
      expireDate.setHours(0, 0, 0, 0)
      return date < expireDate
    }

    const disabledTime = (time) => {
      // Creare un oggetto Date per l'orario di scadenza
      const expireDate = new Date(campaign.value.expire)
      // Estrarre anno, mese e giorno da expireDate
      const expireYear = expireDate.getFullYear()
      const expireMonth = expireDate.getMonth() // Mese basato su zero
      const expireDay = expireDate.getDate()

      // Estrai anno, mese e giorno dall'oggetto time
      const timeYear = time.getFullYear()
      const timeMonth = time.getMonth()
      const timeDay = time.getDate()

      // Verifica se la data è la stessa
      const isSameDay = expireYear === timeYear && expireMonth === timeMonth && expireDay === timeDay

      if (isSameDay) {
        // Estrai ore e minuti
        const expireHours = expireDate.getHours()
        const expireMinutes = expireDate.getMinutes()
        const timeHours = time.getHours()
        const timeMinutes = time.getMinutes()

        // Confronta l'orario con quello di scadenza
        const expireTimeInMinutes = expireHours * 60 + expireMinutes
        const timeInMinutes = timeHours * 60 + timeMinutes

        // Disabilita se l'orario è precedente all'orario di scadenza
        return timeInMinutes < expireTimeInMinutes
      }

      // Se non è lo stesso giorno, non disabilitare alcun orario
      return false
    }

    return {
      ...toRefs(state),
      update,
      updateIndeedLocations,
      addSelectedPlaces,
      addSelectedIndeedPlaces,
      dropdownShouldOpen,
      campaign,
      description,
      umanaValues1,
      umanaValues2,
      umanaValues3,
      umanaSeniority,
      updateCampaign,
      isUmana,
      isAutogrill,
      openCampaignInLandingPage,
      openCampaignInRadar,
      isCampaignPrivate,
      yesOrNot,
      getCertifications,
      getSkills,
      addNewLanguage,
      openInNewTab,
      createUserPiattaforma,
      permissions,
      shortcuts,
      disabledDate,
      disabledTime,
      updateTimePicker
    }
  }
}
</script>
<style lang="scss">
.switchInTitle {
  display: flex !important;
  width: 100%;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  align-content: center;
  align-items: center;

  div {
    margin: 0 !important;
  }
}

form div,
.ql-header * {
  color: white;
}

.ql-snow .ql-stroke {
  stroke: white !important;
}

.ql-container,
.ql-editor {
  background: #004177 !important;
  color: white !important;
}

.ql-active {
  color: #004177 !important;
  background: var(--accented) !important;
}

.ql-picker-options {
  background: #004177 !important;
  color: white !important;
}

.ql-editor>* {
  color: white;

  strong {
    font-weight: 600;
  }
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover {
  background-color: #4ba9c58c !important;
}

.vs__actions {
  path {
    stroke: white !important;
    fill: white !important;
  }
}

.vs__selected {
  background: #4ba8c535;
  margin-bottom: 2px;
  color: white !important;
}

.v-select {
  padding: 0 !important;

  svg {
    margin: 2px 3px;
    fill: white;
  }

  .vs__dropdown-toggle {
    padding: 0;
    border: 0;

    .vs__selected-options {
      padding: 0;
    }
  }
}

.vs__dropdown-menu {
  background: #000000b5;
  padding: 0;
  color: white;
  top: 100%;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 3px;
  z-index: 30;
  position: absolute;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  width: 100%;
  top: 105% !important;
  border: 1px solid #01325a;

  li {
    padding: 5px 8px !important;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    background: #004176;
    //background: radial-gradient(circle at 50% 100%, #008bdb56, #076fd03a);
  }
}

form.readonly {

  .vs__actions,
  .vs__deselect {
    display: none !important;
  }
}
</style>
<style lang="scss" scoped>
@import "../../../assets/scss/lists.scss";

.switch-container {
  display: flex;
  margin-top: -25px;

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    display: none;
  }

  .slider {
    transform: scale(0.7);
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid white;
    background-color: transparent;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &::before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    &.disabled {
      border: 1px solid #a1a1a155;
      background-color: #a1a1a155;
      cursor: default;
    }
  }

  input:checked+.slider:before {
    background-color: var(--accented);
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #101010;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

form {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 50vh;
  overflow-y: auto;
  padding: 12px 25px;
}

.group {
  position: relative;
  display: flex;
  flex-direction: column;
}

.modalOptions {
  background: #000000b5;
  color: white;
  top: 100%;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 3px;
  z-index: 30;
  position: absolute;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.15);

  ul {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    background: radial-gradient(circle at 50% 100%, #008bdb56, #076fd03a);

    li {
      padding: 8px 10px;
      text-align: left;
      border-bottom: 0;
      transition: all 1s ease-in-out;
      position: relative;
    }

    li:hover {
      color: var(--accented);
      background: linear-gradient(90deg,
          rgba(0, 255, 163, 0.05) 60%,
          rgba(2, 0, 36, 0));
      font-weight: 600;
    }
  }
}

.inputMaterial button.vs__clear {
  transform: scale(0.8);
}

.group-title {
  font-size: 14px;
  margin-bottom: 10px;
}

.action-container {
  width: 100%;
  margin-right: 25px;
  margin-top: 50px;
}

.langs-selected-wrapper {
  flex: 0 0 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;

  div {
    display: flex;
    align-items: center;
    background: #013f72;
    color: white;
    border-radius: 5px;
    font-size: 13px;
    width: max-content;
    padding: 4px 8px;
    margin-right: 4px;
    margin-bottom: 4px;

    svg {
      margin-left: 4px;
    }

    &:hover {
      background: #005295;
      cursor: pointer;
    }
  }
}

.languages-container {
  display: flex;
  padding-top: 20px;
  width: 100%;
  position: relative;
  justify-content: space-between;

  .group-50 {
    //width:50%!important;
  }

  button {
    position: absolute;
    right: -10px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: var(--accented);
    color: white;
    border: 1px solid var(--accented);
  }
}

form.readonly {
  .v-select {
    pointer-events: none !important;
  }
}

.box-ad-image {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .ad-image {
    cursor: pointer;
    width: auto;
    height: 100px;
    -o-object-fit: cover;
    object-fit: contain;
    border-radius: 5px;

    &:hover {
      opacity: 0.8;
    }
  }
}

</style>
<style lang="scss">
.group-datepicker {
  position: relative;
  display: flex!important;
  flex-direction: row!important;
  gap:5px!important;
  align-items: center;
  justify-content: center;
  overflow: visible;
  background-color: #004176!important;
  color: white!important;
  .mx-datepicker:nth-child(1) {
    width:calc(100% - 180px)!important;
  }
  .mx-datepicker:nth-child(2) {
   width:180px!important;
  }
  .mx-datepicker .mx-input-wrapper input{
    background-color: transparent;
    color: white;
    &::placeholder {
       color: white;
    }
  }
  .mx-icon-calendar {
    color: white;
  }
  .mx-input:hover, .mx-input:focus {
    border-color: #badc41;
    & + i svg {
      path {
        fill:  #badc41;
      }
    }
  }
}
  .campaignNewCustomDate {
    left: 0!important;
    top: unset!important;
    bottom: -269px!important;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #004176!important;
    color: white!important;
    display:flex!important;
    flex-direction: row;
    height: 270px;
    border-color:#badc41;
    
    .mx-datepicker-sidebar {
      width: 70px;
      box-sizing: border-box; 
      height: 270px;
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      button {
        color: white;
        text-transform: uppercase;
        font-size: 12px;
      }
    }
    .mx-datepicker-content {
      margin-left: 0;
      .mx-calendar-header *, .mx-calendar-header > * {
        color: white!important;
      }
      .cell.not-current-month.disabled,
      .cell.disabled {
        color:#708d9d;
        background:transparent;
      }
      .cell {
        padding: 2px;
        background:transparent;
        div {
          border-radius: 50%;
          width:100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .cell.active div{
        font-weight: bold!important;
        color: rgb(0, 65, 118);
        background-color: rgb(188, 222, 66);
      }
      .cell:hover div{
        background-color: #1b72a0;
        color: white;
      }
    }
  }
  .campaignNewCustomTime {
    border-color:#badc41;
    left: 0!important;
    top: unset!important;
    bottom: -225px!important;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #004176!important;
    color: white!important;
    display:flex!important;
    flex-direction: row;
    height: 226px;
     .mx-time{
      background-color: #004176;
      color: white;
      width:176px!important;
    }
    .mx-time-column .mx-time-item.disabled {
      color:#708d9d;
      background:transparent;
    }
    .mx-time-item.active {
      font-weight: bold!important;
      color: rgb(0, 65, 118);
      background-color: rgb(188, 222, 66);
    }
     .mx-time-item:not(.active):hover{
        background-color: #1b72a0;
        color: white;
      }
  }
  .campaignNewCustomTime li:hover{
    background-color: #1B72A3;
    color: white;
  }
</style>
